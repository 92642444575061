




























import { Vue, Component, Prop } from 'vue-property-decorator'
import { BModal, BvModalEvent, BOverlay, BButton } from 'bootstrap-vue'
import { showError, showMessage } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { copyTextToClipboard } from '@/helpers/copy'

@Component({
  components: {
    BModal,
    BOverlay,
    BButton,
    FeatherIcon,
  },
})
export default class StudentResetPasswordModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isAdmin!: boolean

  state: 'question' | 'result' = 'question'
  id = 0
  name = ''
  newPassword = ''
  pending = false

  get questionText() {
    return `Вы действительно хотите сбросить пароль пользователю ${this.name}?`
  }

  show({ id, name }: { id: number; name: string }) {
    this.id = id
    this.name = name
    this.$bvModal.show('reset-password-modal')
  }

  confirm(e: BvModalEvent) {
    if (this.state === 'result') {
      return this.$bvModal.hide('reset-password-modal')
    }

    e.preventDefault()
    this.resetPassword()
  }

  async resetPassword() {
    this.pending = true
    try {
      let response: AxiosResponse<{
        newPassword: string
      }>

      if (this.isAdmin) {
        response = await axios.post(`v1/users/${this.id}/reset`)
      } else {
        response = await axios.patch(`v1/students/${this.id}/password`)
      }

      this.newPassword = response!.data.newPassword
      this.state = 'result'
    } catch (error) {
      console.error(error)
      showError('При сбросе пароля произошла ошибка')
    }
    this.pending = false
  }

  async copyPassword() {
    if (
      copyTextToClipboard(
        this.newPassword,
        document.getElementById('password')!,
      )
    ) {
      showMessage('Пароль скопирован в буфер обмена')
    }
  }

  reset() {
    this.state = 'question'
    this.name = ''
    this.id = 0
    this.newPassword = ''
  }
}
