import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { BModal, BOverlay } from '@/components/bootstrap'
import { BvEvent } from 'bootstrap-vue'
import axios from '@/libs/axios'
import Select from '../controls/Select'
import { showError } from '@/helpers/notifications'

interface UserGroup {
  id: number
  title: string
}

@Component
export default class ChangeRoleModal extends tsx.Component<{
  show?: () => Promise<void>
}> {
  modalId = 'change-role-modal'
  pending = false
  groups: UserGroup[] = []
  selectedGroup: UserGroup | null = null
  userId = -1

  async show(userId: number, initGroup: number) {
    this.$bvModal.show(this.modalId)

    this.userId = userId

    this.pending = true

    try {
      const response = await axios.get<{ data: UserGroup[] }>('v1/groups')
      this.groups = response.data.data

      this.selectedGroup = this.groups.find(g => g.id === initGroup)!
    } catch (error) {
      console.error(error)
      showError('При получении списка ролей произошла ошибка')
    }

    this.pending = false
  }

  async submit(e: BvEvent) {
    e.preventDefault()

    this.pending = true
    try {
      await axios.patch(`v1/users/${this.userId}/group`, {
        groupId: this.selectedGroup?.id,
      })

      await this.$store.dispatch('fetchUser', this.userId)
    } catch (error) {
      console.error(error)
      showError('При изменении роли произошла ошибка')
    }
    this.pending = false

    if (true) {
      this.$bvModal.hide(this.modalId)
    }
  }

  onHidden() {
    this.userId = -1
    this.selectedGroup = null
    this.pending = false
  }

  protected render() {
    return (
      <BModal
        id={this.modalId}
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        ok-title="Изменить"
        cancel-title="Отмена"
        centered
        title="Изменение роли"
        onHidden={this.onHidden}
        onOk={this.submit}
        busy={this.pending}
      >
        <BOverlay show={this.pending} no-wrap />

        <Select
          value={this.selectedGroup}
          onInput={value => (this.selectedGroup = value)}
          options={this.groups}
          label="title"
          clearable={false}
          placeholder="Выберите роль"
        />
      </BModal>
    )
  }
}
