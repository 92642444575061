<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar_url"
            :text="avatarText"
            variant="light-primary"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column justify-content-between ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.first_name }} {{ userData.last_name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
              <b-button
                v-if="loginButtonVisible"
                variant="outline-primary"
                class="mt-1 d-block"
                @click="loginUser"
              >
                Войти под аккаунтом
              </b-button>

              <b-button
                variant="primary"
                class="mt-25 d-block"
                @click="resetPassword"
              >
                Сбросить пароль
              </b-button>
              <b-button
                variant="outline-secondary"
                class="mt-25 d-block"
                @click="
                  $refs.changeRoleModal.show(userData.id, userData.group.id)
                "
              >
                {{ userData.group_name }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="3" lg="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span class="font-weight-bolder">Дата рождения</span>
            </th>
            <td class="pb-50">
              {{ formatDate(userData.dob) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bolder">Телефон</span>
            </th>
            <td class="pb-50">
              {{ userData.phone }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="SendIcon" class="mr-75" />
              <span class="font-weight-bolder">Telegram</span>
            </th>
            <td class="pb-50">
              <b-link
                :href="`https://t.me/${userData.telegram}`"
                target="_blank"
              >
                @{{ userData.telegram }}
              </b-link>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>

    <ResetPasswordModal ref="resetPasswordModal" :isAdmin="true" />
    <ChangeRoleModal ref="changeRoleModal" />
  </b-card>
</template>

<script>
import { BCard, BAvatar, BRow, BCol, BLink } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { userStore } from '@/store'
import axios from '@/libs/axios'
import { saveToLS } from '@/helpers/localStorage'
import { TOKEN_KEY } from '@/@core/auth/auth'
import ResetPasswordModal from '@/components/modals/StudentResetPasswordModal.vue'
import ChangeRoleModal from '@/components/user/ChangeRoleModal'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BLink,
    ResetPasswordModal,
    ChangeRoleModal,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    avatarText() {
      return avatarText(
        `${this.userData.first_name} ${this.userData.last_name}`,
      )
    },
    loginButtonVisible() {
      return userStore.user?.group.permissions['admin.account.sessions']
    },
  },
  methods: {
    formatDate(date) {
      return date.split('-').reverse().join('.')
    },
    resetPassword() {
      this.$refs.resetPasswordModal.show({
        id: this.userData.id,
        name: this.userData.full_name,
      })
    },
    async loginUser() {
      try {
        const response = await axios.post('v1/auth/session/token', {
          userId: this.userData.id,
          userType: 'user',
        })

        const response2 = await axios.post('v1/auth/session/login', {
          sessionToken: response.data.sessionToken,
        })

        saveToLS(TOKEN_KEY, response2.data.accessToken)
        this.$router.go(0)
      } catch (error) {
        console.error(error)
        showError('При получении авторизационного токена произошла ошибка')
      }
    },
  },
}
</script>
