<template>
  <div>
    <template v-if="user">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
        >
          <user-personal-info-card :user-data="user" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <user-info-address-card :user-data="user" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import UserPersonalInfoCard from '@/components/user/UserPersonalInfoCard.vue'
import UserInfoAddressCard from '@/components/user/UserInfoAddressCard.vue'

export default {
  components: {
    BRow,
    BCol,

    UserPersonalInfoCard,
    UserInfoAddressCard,
  },
  computed: {
    ...mapGetters({
      user: 'getCurrentUser',
    }),
  },
}
</script>

<style>
</style>
