<template>
  <b-card title="Адрес">
    <b-col
      cols="21"
    >
      <table class="mt-2 mt-xl-0 w-100">
        <tr>
          <th class="pb-50">
            <span class="font-weight-bolder">Страна</span>
          </th>
          <td class="pb-50">
            {{ userData.country.name }}
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <span class="font-weight-bolder">Населенный пункт</span>
          </th>
          <td class="pb-50 text-capitalize">
            {{ userData.city.name }}
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <span class="font-weight-bolder">Улица</span>
          </th>
          <td class="pb-50">
            {{ userData.street }}
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <span class="font-weight-bolder">Дом</span>
          </th>
          <td class="pb-50">
            {{ userData.house }}
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <span class="font-weight-bolder">Корпус</span>
          </th>
          <td class="pb-50">
            {{ userData.block || 'Отсутствует' }}
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <span class="font-weight-bolder">Квартира</span>
          </th>
          <td class="pb-50">
            {{ userData.apartment }}
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <span class="font-weight-bolder">Индекс</span>
          </th>
          <td class="pb-50">
            {{ userData.post_index }}
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <span class="font-weight-bolder">Отделение “Новой почты”</span>
          </th>
          <td class="pb-50">
            {{ userData.post_office || 'Отсутствует' }}
          </td>
        </tr>
      </table>
    </b-col>
  </b-card>
</template>

<script>
import {
  BCard, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCol,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
